import * as prismicH from '@prismicio/helpers'
import linkResolver from './link-resolver'

function getSrcSet(element) {
  return `
    ${element.url}?w=${element.dimensions.width}&${
    element.dimensions.height
  }&cs=srgb ${element.dimensions.width}w,
    ${element.url}?w=${element.dimensions.width}&${
    element.dimensions.height
  }&cs=srgb&dpr=2 ${element.dimensions.width * 2}w`
}

export default function (type, element, content, $contextPath) {
  function getURL() {
    let url = prismicH.asLink(element.data, linkResolver)
    if ($contextPath) {
      url = $contextPath(url)
    }
    return url
  }

  // Generate links to Prismic Documents as <router-link> components
  if (type === prismicH.Element.hyperlink) {
    if (element.data.link_type === 'Document') {
      const url = getURL()
      return `<a href="${url}" data-nuxt-link>${content}</a>`
    }
  }

  // If an image links to a Prismic Document, it will return a <router-link> component
  if (
    type === prismicH.Element.image &&
    element.linkTo &&
    element.data.link_type === 'Document'
  ) {
    if (element.data.link_type === 'Document') {
      const url = getURL()

      return `<a href="${url}" data-nuxt-link><img src="${element.url}" alt="${
        element.alt || ''
      }" copyright="${element.copyright || ''}"></a>`
    }
  }

  if (type === prismicH.Element.image) {
    return `<div class="lazy-image-container" style="width: ${
      element.dimensions.width
    }px"><picture class="rich-text__lazy lazy-image" data-alt="${
      element.alt
    }" style="padding-top: ${
      (element.dimensions.height / element.dimensions.width) * 100
    }%;">
      <source srcset="${getSrcSet(element)}" sizes="${
      element.dimensions.width
    }px" />
    </picture></div>`
  }

  // Return null to stick with the default behavior for everything else.
  return null
}
