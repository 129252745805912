<template>
  <component
    :is="titleComponent"
    v-if="isFilled"
    class="prismic-title"
    :class="{
      'no-center': noCenter,
      'no-max-width': noMaxWidth,
      'd-flex align-center': icon,
    }"
  >
    <icon v-if="icon" :name="icon" class="mr-1" />
    {{ titleAsText }}
  </component>
</template>

<script>
import * as prismicH from '@prismicio/helpers'

import Title1 from './Title1'
import Title2 from './Title2'
import Title3 from './Title3'
import Title4 from './Title4'
import Title5 from './Title5'

import Icon from '@/components/icons/Icon'

export default {
  name: 'PrismicTitle',
  components: {
    Icon,
  },
  props: {
    title: {
      type: Array,
      required: true,
    },
    noCenter: { type: Boolean, default: false },
    noMaxWidth: { type: Boolean, default: false },
    icon: { type: String, default: null },
  },
  computed: {
    isFilled() {
      return prismicH.isFilled.title(this.title)
    },
    titleAsText() {
      if (this.isFilled) {
        return prismicH.asText(this.title)
      }

      return null
    },
    titleComponent() {
      const heading = {
        heading1: Title1,
        heading2: Title2,
        heading3: Title3,
        heading4: Title4,
        heading5: Title5,
      }
      return this.isFilled ? heading[this.title[0].type] : null
    },
  },
}
</script>

<style lang="scss">
.prismic-title {
  max-width: rem(720px);
  margin-left: auto;
  margin-right: auto;

  &.no-center {
    margin-left: 0;
    margin-right: 0;
  }

  &.no-max-width {
    max-width: none;
  }
}
</style>
